
import { AllCreativeConstants } from './creative.constants';

export const creative = (state = {}, action) => {
    switch (action.type) {

        case AllCreativeConstants.EMPTY_BRIEF_CREATIVE_BY_CAMPAIGN_ID: return { ...state, creatives: "" }
        case AllCreativeConstants.GET_BRIEF_CREATIVE_BY_CAMPAIGN_ID:
        case AllCreativeConstants.GET_BRIEF_CREATIVE_BY_CAMPAIGN_ID_ERROR: return { ...state, creatives: action.payload }

        case AllCreativeConstants.EMPTY_BRIEF_CREATIVE_BY_ID: return { ...state, profile: "" }
        case AllCreativeConstants.GET_BRIEF_CREATIVE_BY_ID:
        case AllCreativeConstants.GET_BRIEF_CREATIVE_BY_ID_ERROR: return { ...state, profile: action.payload }

        case AllCreativeConstants.EMPTY_ALL_VERSIONS_BRIEF_CREATIVE: return { ...state, allVCreatives: "" }
        case AllCreativeConstants.GET_ALL_VERSIONS_BRIEF_CREATIVE:
        case AllCreativeConstants.GET_ALL_VERSIONS_BRIEF_CREATIVE_ERROR: return { ...state, allVCreatives: action.payload }

        case AllCreativeConstants.BRIEF_CREATIVE_LOADING_STATUS: return { ...state, loading: action.payload }

        case AllCreativeConstants.DELETE_BRIEF_CREATIVE:
        case AllCreativeConstants.DELETE_BRIEF_CREATIVE_ERROR:
            return { ...state, deleteStatus: action.payload }

        case AllCreativeConstants.ACTIVATE_BRIEF_CREATIVE:
        case AllCreativeConstants.ACTIVATE_BRIEF_CREATIVE_ERROR:
            return { ...state, activateStatus: action.payload }

        case AllCreativeConstants.DISREGARD_BRIEF_CREATIVE:
        case AllCreativeConstants.DISREGARD_BRIEF_CREATIVE_ERROR:
            return { ...state, disregardStatus: action.payload }

        case AllCreativeConstants.APPROVE_BRIEF_CREATIVE:
        case AllCreativeConstants.APPROVE_BRIEF_CREATIVE_ERROR:
            return { ...state, approveStatus: action.payload }
            
        case AllCreativeConstants.UPDATE_CREATIVE_STATUSES:
        case AllCreativeConstants.UPDATE_CREATIVE_STATUSES:
            return { ...state, updateStatuses: action.payload }

        case AllCreativeConstants.RECOVER_BRIEF_CREATIVE:
        case AllCreativeConstants.RECOVER_BRIEF_CREATIVE_ERROR:
            return { ...state, recoverStatus: action.payload }

            
        case AllCreativeConstants.PUBLISH_BRIEF_CREATIVE:
        case AllCreativeConstants.PUBLISH_BRIEF_CREATIVE_ERROR:
            return { ...state, publishStatus: action.payload }
        default:
            return state;
    }
}
