import { AdBuilderCreativeConstants } from './adBuilder.constants';
import { NexdBuilderConstants } from './nexdbuilder/nexdBuilder.constants'
import { AirtoryBuilderConstants } from './airtorybuilder/airtoryBuilder.constants'
import { PrimoBuilderConstants } from './primobuilder/primoBuilder.constants'

export const SwipeAdBuilder = (state = {}, action) => {
    switch (action.type) {
        case AdBuilderCreativeConstants.SWIPE_CREATE_REQUEST_SUCCESS:
            return { ...state, addSwipeSuccess: true, ...action.payload };

        case AdBuilderCreativeConstants.SWIPE_CREATE_REQUEST_ERROR:
            return { ...state, addSwipeSuccess: false, ...action.payload };

        case AdBuilderCreativeConstants.SWIPE_CREATE_REQUEST_LOADING:
            return { ...state, addSwipeLoading: action.payload };

        case AdBuilderCreativeConstants.SWIPE_FINALIZE_REQUEST_SUCCESS:
            return { ...state, addScriptSuccess: true, ...action.payload };

        case AdBuilderCreativeConstants.SWIPE_FINALIZE_REQUEST_ERROR:
            return { ...state, addScriptSuccess: false, ...action.payload };

        case AdBuilderCreativeConstants.IMPRESSION_CHECK_SUCCESS:
            return { ...state, impressionCheckSuccess: true, ...action.payload };

        case AdBuilderCreativeConstants.IMPRESSION_CHECK_ERROR:
            return { ...state, impressionCheckSuccess: false, ...action.payload };

        case AdBuilderCreativeConstants.IMPRESSION_CHECK_LOADING:
            return { ...state, impressionCheckLoading: action.payload };

        default:
            return state;
    }
}

export const AdBuilder = (state = {}, action) => {

    switch (action.type) {
        case AdBuilderCreativeConstants.BB_CREATIVE_LOADING:
            return { ...state, loading: action.payload };
        case AdBuilderCreativeConstants.BB_ADD_CREATIVE_SUCCESS:
            return { ...state, ...action.payload };
        case AdBuilderCreativeConstants.BB_ADD_CREATIVE_ERROR:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

export const Advertisements = (state = {}, action) => {

    switch (action.type) {
        case AdBuilderCreativeConstants.BB_UNMOUNT:
            return { ...state, adData: "" };
        case AdBuilderCreativeConstants.BB_UNMOUNT_ADS:
            return { ...state, data: "" };
        case AdBuilderCreativeConstants.GET_ALL_ADVERTISEMENT:
            return { ...state, data: action.payload }
        case AdBuilderCreativeConstants.GET_ALL_ADVERTISEMENT_ERROR:
            return { ...state, data: action.payload }

        case AdBuilderCreativeConstants.ADVERTISEMENT_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case AdBuilderCreativeConstants.ADVERTISEMENT_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload }

        case AdBuilderCreativeConstants.ADVERTISEMENT_SEARCH_VALUE:
            return { ...state, search: action.payload }

        case AdBuilderCreativeConstants.ADVERTISEMENT_FILTER_STATUS:
            return { ...state, status: action.payload }

        case AdBuilderCreativeConstants.ADVERTISEMENT_TOTAL_COUNT:
            return { ...state, total: action.payload }

        case AdBuilderCreativeConstants.ADVERTISEMENT_LOADING_STATUS:
            return { ...state, loading: action.payload }

        case AdBuilderCreativeConstants.GET_ADVERTISEMENT_BY_ID:
            return { ...state, adData: action.payload, error: '' }
        case AdBuilderCreativeConstants.GET_ADVERTISEMENT_BY_ID_ERROR:
            return { ...state, adData: false, error: action.payload }

        case AdBuilderCreativeConstants.ADD_DSP_DETAILS:
            return { ...state, addDSPStatus: true, addDSPResp: action.payload }
        case AdBuilderCreativeConstants.ADD_DSP_DETAILS_ERROR:
            return { ...state, addDSPStatus: false, addDSPResp: action.payload }

        case AdBuilderCreativeConstants.EMPTY_DSP:
            return { ...state, dspData: "" }

        case AdBuilderCreativeConstants.DSP_INFO_LOADING_STATUS:
            return { ...state, dspLoading: true }
        case AdBuilderCreativeConstants.GET_DSP_INFO_BY_ID:
            return { ...state, dspData: action.payload, dspLoading: false }
        case AdBuilderCreativeConstants.GET_DSP_INFO_BY_ID_ERROR:
            return { ...state, dspData: action.payload, dspLoading: false }

        case AdBuilderCreativeConstants.REMOVE_DSP_INFO:
            return { ...state, dspData: action.payload }

        case AdBuilderCreativeConstants.GET_ADS_BY_BRIEF_ID:
            return { ...state, briefAds: action.payload }
        case AdBuilderCreativeConstants.GET_ADS_BY_BRIEF_ID_ERROR:
            return { ...state, briefAds: action.payload }

        case AdBuilderCreativeConstants.AD_STATUS_UPDATE:
            return { ...state, updateStatus: true, statusResp: action.payload }
        case AdBuilderCreativeConstants.AD_STATUS_UPDATE_ERROR:
            return { ...state, updateStatus: false, statusResp: action.payload }
        case AdBuilderCreativeConstants.SEND_EMAIL_PREVIEW_LINK:
            return { ...state, emailStatus: true, emailResp: action.payload }

        case AdBuilderCreativeConstants.BB_AD_APPROVE_SUCCESS:
            return { ...state, approveSuccess: true, approveResp: action.payload };
        case AdBuilderCreativeConstants.BB_AD_APPROVE_ERROR:
            return { ...state, approveSuccess: false, ...action.payload };
        case AdBuilderCreativeConstants.BB_AD_REVIEW_SUCCESS:
            return { ...state, reviewSuccess: true, reviewResp: action.payload };
        case AdBuilderCreativeConstants.BB_AD_REVIEW_ERROR:
            return { ...state, reviewSuccess: false, ...action.payload };


        case AdBuilderCreativeConstants.AD_INFO_LOADING_STATUS:
            return { ...state, adInfoLoading: true }
        case AdBuilderCreativeConstants.GET_AD_INFO_BY_ID:
            return { ...state, adInfoData: action.payload, adInfoLoading: false }
        case AdBuilderCreativeConstants.GET_AD_INFO_BY_ID_ERROR:
            return { ...state, adInfoData: action.payload, adInfoLoading: false }

        case AdBuilderCreativeConstants.AD_ASSETS_LOADING_STATUS:
            return { ...state, adAssetsLoading: true }
        case AdBuilderCreativeConstants.GET_AD_ASSETS_BY_ID:
            return { ...state, adAssetsData: action.payload, adAssetsLoading: false }
        case AdBuilderCreativeConstants.GET_AD_ASSETS_BY_ID_ERROR:
            return { ...state, adAssetsData: action.payload, adAssetsLoading: false }
        case AdBuilderCreativeConstants.EMPTY_AD_ASSETS_DATA:
            return { ...state, adAssetsData: "", adInfoData: "" }

            case AdBuilderCreativeConstants.AD_CAMPAIGN_LOADING_STATUS:
                return { ...state, adCampaignInfoLoading: true }
            case AdBuilderCreativeConstants.GET_AD_CAMPAIGN_BY_ID:
                return { ...state, adCampaignInfoData: action.payload, adCampaignInfoLoading: false }
            case AdBuilderCreativeConstants.GET_AD_CAMPAIGN_BY_ID_ERROR:
                return { ...state, adCampaignInfoData: action.payload, adCampaignInfoLoading: false }
            case AdBuilderCreativeConstants.EMPTY_AD_CAMPAIGN_DATA:
                return { ...state, adCampaignInfoData: "" }    

        case NexdBuilderConstants.NEXD_STATUS_LOG_INIT:
            return { ...state, adNexdStatus: { loading: true, ...action.payload } }
        case NexdBuilderConstants.NEXD_STATUS_LOG_SUCCESS:
            return { ...state, adNexdStatus: { loading: false, ...action.payload } }
        case NexdBuilderConstants.NEXD_STATUS_LOG_ERROR:
            return { ...state, adNexdStatus: { loading: false, ...action.payload } }

        default:
            return state;
    }
};

export const MyAdvertisements = (state = {}, action) => {

    switch (action.type) {
        case AdBuilderCreativeConstants.MY_GET_ALL_ADVERTISEMENT:
            return { ...state, data: action.payload }
        case AdBuilderCreativeConstants.MY_GET_ALL_ADVERTISEMENT_ERROR:
            return { ...state, data: action.payload }

        case AdBuilderCreativeConstants.MY_ADVERTISEMENT_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case AdBuilderCreativeConstants.MY_ADVERTISEMENT_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload }

        case AdBuilderCreativeConstants.MY_ADVERTISEMENT_SEARCH_VALUE:
            return { ...state, search: action.payload }

        case AdBuilderCreativeConstants.MY_ADVERTISEMENT_FILTER_STATUS:
            return { ...state, status: action.payload }

        case AdBuilderCreativeConstants.MY_ADVERTISEMENT_TOTAL_COUNT:
            return { ...state, total: action.payload }

        case AdBuilderCreativeConstants.MY_ADVERTISEMENT_LOADING_STATUS:
            return { ...state, loading: action.payload }

        case AdBuilderCreativeConstants.AD_STATUS_DELETE:
            return { ...state, deleteResp: action.payload, deleteSuccess: true }

        case AdBuilderCreativeConstants.AD_STATUS_DELETE_ERROR:
            return { ...state, deleteResp: action.payload, deleteSuccess: false }

        case AdBuilderCreativeConstants.DELETE_LOADING_STATUS:
            return { ...state, deleteLoading: action.payload }

        case AdBuilderCreativeConstants.AD_STATUS_CLONE:
            return { ...state, cloneResp: action.payload, cloneSuccess: true }

        case AdBuilderCreativeConstants.AD_STATUS_CLONE_ERROR:
            return { ...state, cloneResp: action.payload, cloneSuccess: false }

        case AdBuilderCreativeConstants.CLONE_LOADING_STATUS:
            return { ...state, cloneLoading: action.payload }

        case AdBuilderCreativeConstants.AD_STATUS_RENAME:
            return { ...state, renameResp: action.payload, renameSuccess: true }

        case AdBuilderCreativeConstants.AD_STATUS_RENAME_ERROR:
            return { ...state, renameResp: action.payload, renameSuccess: false }

        case AdBuilderCreativeConstants.RENAME_LOADING_STATUS:
            return { ...state, renameLoading: action.payload }

        case AdBuilderCreativeConstants.AD_STATUS_START_STOP:
            return { ...state, ...action.payload }

        case AdBuilderCreativeConstants.AD_STATUS_START_STOP_ERROR:
            return { ...state, ...action.payload }

        case AdBuilderCreativeConstants.START_STOP_LOADING_STATUS:
            return { ...state, startStopLoading: action.payload }

        case AdBuilderCreativeConstants.BB_DOWNLOAD_INIT:
            return { ...state, downloadLoading: true }
        case AdBuilderCreativeConstants.BB_DOWNLOAD_ERROR:
        case AdBuilderCreativeConstants.BB_DOWNLOAD_SUCCESS:
            return { ...state, downloadLoading: false, downloadSuccess: action.payload, downloadResp: new Date() }

        case AdBuilderCreativeConstants.AD_ARCHIVE_TOGGLE_INIT: return { ...state, archive: { loading: true } }
        case AdBuilderCreativeConstants.AD_ARCHIVE_TOGGLE_SUCCESS: return { ...state, archive: action.payload }
        case AdBuilderCreativeConstants.AD_ARCHIVE_TOGGLE_ERROR: return { ...state, archive: action.payload }

        default:
            return state;
    }
};

export const VendorAdvertisements = (state = {}, action) => {

    switch (action.type) {
        case AdBuilderCreativeConstants.VENDOR_GET_ALL_ADVERTISEMENT:
            return { ...state, data: action.payload }
        case AdBuilderCreativeConstants.VENDOR_GET_ALL_ADVERTISEMENT_ERROR:
            return { ...state, data: action.payload }

        case AdBuilderCreativeConstants.VENDOR_ADVERTISEMENT_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case AdBuilderCreativeConstants.VENDOR_ADVERTISEMENT_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload }

        case AdBuilderCreativeConstants.VENDOR_ADVERTISEMENT_SEARCH_VALUE:
            return { ...state, search: action.payload }

        case AdBuilderCreativeConstants.VENDOR_ADVERTISEMENT_TOTAL_COUNT:
            return { ...state, total: action.payload }

        case AdBuilderCreativeConstants.VENDOR_ADVERTISEMENT_LOADING_STATUS:
            return { ...state, loading: action.payload }

        default:
            return state;
    }
};

export const NexdAdBuilder = (state = {}, action) => {
    switch (action.type) {

        case AdBuilderCreativeConstants.NEXD_CREATE_REQUEST_SUCCESS:
            return { ...state, addNexdSuccess: true, ...action.payload }
        case AdBuilderCreativeConstants.NEXD_CREATE_REQUEST_ERROR:
            return { ...state, addNexdSuccess: false, ...action.payload }

        case AdBuilderCreativeConstants.NEXD_EDIT_REQUEST_SUCCESS:
            return { ...state, editNexdSuccess: true, ...action.payload }
        case AdBuilderCreativeConstants.NEXD_EDIT_REQUEST_ERROR:
            return { ...state, editNexdSuccess: false, ...action.payload }

        case AdBuilderCreativeConstants.NEXD_CREATE_REQUEST_LOADING:
            return { ...state, addNexdLoading: action.payload }

        case AdBuilderCreativeConstants.NEXD_PUBLISH_LOADING:
            return { ...state, nexdPublishLoading: action.payload }

        case AdBuilderCreativeConstants.NEXD_PUBLISH_SUCCESS:
            return { ...state, nexdPublishSuccess: true, nexdPublishDate: new Date() + "success", ...action.payload }

        case AdBuilderCreativeConstants.NEXD_PUBLISH_ERROR:
            return { ...state, nexdPublishSuccess: false, nexdPublishDate: new Date() + "err", ...action.payload }

        case AdBuilderCreativeConstants.NEXD_BUILDER_PUBLISH_LOADING:
            return { ...state, nexdBuilderPublishLoading: action.payload }

        case AdBuilderCreativeConstants.NEXD_BUILDER_PUBLISH_SUCCESS:
            return { ...state, nexdBuilderPublishSuccess: true, nexdBuilderPublishDate: new Date() + "success", ...action.payload }

        case AdBuilderCreativeConstants.NEXD_BUILDER_PUBLISH_ERROR:
            return { ...state, nexdBuilderPublishSuccess: false, nexdBuilderPublishDate: new Date() + "err", ...action.payload }
        
        case NexdBuilderConstants.NEXD_OPTIMISATION_SUCCESS:
                return { ...state, ...action.payload }
        case NexdBuilderConstants.NEXD_OPTIMISATION_ERROR:
                return { ...state, ...action.payload }


        default:
            return state;

    }
};
export const CreativeReport = (state = {}, action) => {
    switch (action.type) {
        case AdBuilderCreativeConstants.NEXD_REPORT_LOADING:
            return { ...state, nexdReportLoading: action.payload }

        case AdBuilderCreativeConstants.GET_AD_NEXD_REPORT_SUCCESS:
            return { ...state, nexdReportSuccess: new Date(), nexdReportdata: action.payload }
        case AdBuilderCreativeConstants.GET_AD_NEXD_REPORT_ERROR:
            return { ...state, nexdReportSuccess: new Date(), nexdReportdata: action.payload }
        case AdBuilderCreativeConstants.UNMOUNT_AD_NEXD_REPORT:
            return { ...state, nexdReportSuccess: new Date(), nexdReportdata: "" }

        case AdBuilderCreativeConstants.WIDESPACE_REPORT_LOADING:
            return { ...state, wideSpaceReportLoading: action.payload }

        case AdBuilderCreativeConstants.GET_AD_WIDESPACE_REPORT_SUCCESS:
            return { ...state, wideSpaceReportSuccess: new Date(), wideSpaceReportdata: action.payload }
        case AdBuilderCreativeConstants.GET_AD_WIDESPACE_REPORT_ERROR:
            return { ...state, wideSpaceReportSuccess: new Date(), wideSpaceReportdata: "ERROR" }
        case AdBuilderCreativeConstants.UNMOUNT_AD_WIDESPACE_REPORT:
            return { ...state, wideSpaceReportSuccess: new Date(), wideSpaceReportdata: "" }

        case AdBuilderCreativeConstants.AIRTORY_REPORT_LOADING:
            return { ...state, airtoryReportLoading: action.payload }

        case AdBuilderCreativeConstants.GET_AD_AIRTORY_REPORT_SUCCESS:
            return { ...state, airtoryReportSuccess: new Date(), airtoryReportdata: action.payload }
        case AdBuilderCreativeConstants.GET_AD_AIRTORY_REPORT_ERROR:
            return { ...state, airtoryReportSuccess: new Date(), airtoryReportdata: action.payload }
        case AdBuilderCreativeConstants.UNMOUNT_AD_AIRTORY_REPORT:
            return { ...state, airtoryReportSuccess: new Date(), airtoryReportdata: "" }

        case AdBuilderCreativeConstants.PRIMO_REPORT_LOADING:
            return { ...state, primoReportLoading: action.payload }

        case AdBuilderCreativeConstants.GET_AD_PRIMO_REPORT_SUCCESS:
            return { ...state, primoReportSuccess: new Date(), primoReportdata: action.payload }
        case AdBuilderCreativeConstants.GET_AD_PRIMO_REPORT_ERROR:
            return { ...state, primoReportSuccess: new Date(), primoReportdata: action.payload }
        case AdBuilderCreativeConstants.UNMOUNT_AD_PRIMO_REPORT:
            return { ...state, primoReportSuccess: new Date(), primoReportdata: "" }

        case AdBuilderCreativeConstants.DEAL_REPORT_LOADING:
            return { ...state, dealReportLoading: action.payload }
        case AdBuilderCreativeConstants.GET_AD_DEAL_REPORT_SUCCESS:
            return { ...state, dealReportSuccess: new Date(), dealReportdata: action.payload }
        case AdBuilderCreativeConstants.GET_AD_DEAL_REPORT_ERROR:
            return { ...state, dealReportSuccess: new Date(), dealReportdata: action.payload }
        case AdBuilderCreativeConstants.UNMOUNT_AD_DEAL_REPORT:
            return { ...state, dealReportSuccess: new Date(), dealReportdata: "" }
        case AdBuilderCreativeConstants.DEAL_DOWNLOAD_REPORT_LOADING:
            return { ...state, downloadReportLoading: action.payload }
        case AdBuilderCreativeConstants.CO2_DOWNLOAD_REPORT_LOADING:
            return { ...state, downloadCO2ReportLoading: action.payload }
        case AdBuilderCreativeConstants.DOWNLOAD_EMISSION_OPTIMISATION_REPORT_LOADING:
            return { ...state, downloadEmissionsOptimisationReportLoading: action.payload }

        case AdBuilderCreativeConstants.GET_AD_DEAL_REPORT_OPTIMISATION_LOADING:
            return { ...state, optimisationReportLoading: action.payload }
        case AdBuilderCreativeConstants.GET_AD_DEAL_REPORT_OPTIMISATION_SUCCESS:
            return { ...state, optimisationReport: action.payload }
        case AdBuilderCreativeConstants.GET_AD_DEAL_REPORT_OPTIMISATION_ERROR:
            return { ...state, optimisationReport: action.payload }

        case AdBuilderCreativeConstants.GET_EMISSION_LOADING:
            return { ...state, emissionReportLoading: action.payload }
        case AdBuilderCreativeConstants.GET_EMISSION_SUCCESS:
        case AdBuilderCreativeConstants.GET_EMISSION_ERROR:
            return { ...state, emissionReportSuccess: new Date(), emissionReport: action.payload }
            
        case AdBuilderCreativeConstants.GET_EMISSION_SUMMARY_LOADING:
            return { ...state, emissionSummaryLoading: action.payload }
        case AdBuilderCreativeConstants.GET_EMISSION_SUMMARY_SUCCESS:
        case AdBuilderCreativeConstants.GET_EMISSION_SUMMARY_ERROR:
            return { ...state, emissionSummarySuccess: new Date(), emissionSummary: action.payload }
        default:
            return state;

    }
};

export const NexdBuilder = (state = {}, action) => {
    switch (action.type) {
        case NexdBuilderConstants.NEXD_BUILDER_ASSET_LOADING:
            return { ...state, nexdAssetLoading: action.payload }
        case NexdBuilderConstants.NEXD_BUILDER_PREVIEW_LOADING:
            return { ...state, nexdPreviewLoading: action.payload }
        case NexdBuilderConstants.NEXD_BUILDER_CREATIVE_LOADING:
            return { ...state, nexdBuilderLoading: action.payload }
        case NexdBuilderConstants.NEXD_BUILDER_UNMOUNT:
            return {}
        case NexdBuilderConstants.NEXD_BUILDER_NEW_CREATIVE_SUCCESS:
        case NexdBuilderConstants.NEXD_BUILDER_NEW_CREATIVE_ERROR:
        case NexdBuilderConstants.NEXD_BUILDER_ADD_ASSET_SUCCESS:
        case NexdBuilderConstants.NEXD_BUILDER_ADD_ASSET_ERROR:
        case NexdBuilderConstants.NEXD_GENERATE_PREVIEW_SUCCESS:
        case NexdBuilderConstants.NEXD_GENERATE_PREVIEW_ERROR:
        case NexdBuilderConstants.NEXD_GET_TAG_SUCCESS:
        case NexdBuilderConstants.NEXD_GET_TAG_ERROR:
        case NexdBuilderConstants.NEXD_BUILDER_REMOVE_ASSET_SUCCESS:
        case NexdBuilderConstants.NEXD_BUILDER_REMOVE_ASSET_ERROR:
        case NexdBuilderConstants.NEXD_BUILDER_UPDATE_SUCCESS:
        case NexdBuilderConstants.NEXD_BUILDER_UPDATE_ERROR:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};


export const AirtoryBuilder = (state = {}, action) => {
    switch (action.type) {
        case AirtoryBuilderConstants.AIRTORY_BUILDER_ASSET_LOADING:
            return { ...state, airtoryAssetLoading: action.payload }
        case AirtoryBuilderConstants.AIRTORY_BUILDER_PREVIEW_LOADING:
            return { ...state, airtoryPreviewLoading: action.payload }
        case AirtoryBuilderConstants.AIRTORY_BUILDER_CREATIVE_LOADING:
            return { ...state, airtoryBuilderLoading: action.payload }
        case AirtoryBuilderConstants.AIRTORY_BUILDER_SOCIALMEDIA_DATA_LOADING:
            return { ...state, airtorySocialLoading: action.payload }
        case AirtoryBuilderConstants.AIRTORY_BUILDER_UNMOUNT:
            return {}
        case AirtoryBuilderConstants.AIRTORY_BUILDER_NEW_CREATIVE_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_NEW_CREATIVE_ERROR:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_ADD_ASSET_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_ADD_ASSET_ERROR:
        case AirtoryBuilderConstants.AIRTORY_GENERATE_PREVIEW_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_GENERATE_PREVIEW_ERROR:
        case AirtoryBuilderConstants.AIRTORY_GET_TAG_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_GET_TAG_ERROR:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_REMOVE_ASSET_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_REMOVE_ASSET_ERROR:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_UPDATE_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_UPDATE_ERROR:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_SOCIALMEDIA_DATA_SUCCESS:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_SOCIALMEDIA_DATA_ERROR:
        case AirtoryBuilderConstants.AIRTORY_BUILDER_EMPTY_ASSET_ID:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};


export const PrimoBuilder = (state = {}, action) => {
    switch (action.type) {
        case PrimoBuilderConstants.PRIMO_BUILDER_ASSET_LOADING:
            return { ...state, primoAssetLoading: action.payload }
        case PrimoBuilderConstants.PRIMO_BUILDER_PREVIEW_LOADING:
            return { ...state, primoPreviewLoading: action.payload }
        case PrimoBuilderConstants.PRIMO_BUILDER_CREATIVE_LOADING:
            return { ...state, primoBuilderLoading: action.payload }
        case PrimoBuilderConstants.PRIMO_BUILDER_UNMOUNT:
            return {}
        case PrimoBuilderConstants.PRIMO_BUILDER_NEW_CREATIVE_SUCCESS:
        case PrimoBuilderConstants.PRIMO_BUILDER_NEW_CREATIVE_ERROR:
        case PrimoBuilderConstants.PRIMO_BUILDER_ADD_ASSET_SUCCESS:
        case PrimoBuilderConstants.PRIMO_BUILDER_ADD_ASSET_ERROR:
        case PrimoBuilderConstants.PRIMO_GENERATE_PREVIEW_SUCCESS:
        case PrimoBuilderConstants.PRIMO_GENERATE_PREVIEW_ERROR:
        case PrimoBuilderConstants.PRIMO_GET_TAG_SUCCESS:
        case PrimoBuilderConstants.PRIMO_GET_TAG_ERROR:
        case PrimoBuilderConstants.PRIMO_BUILDER_REMOVE_ASSET_SUCCESS:
        case PrimoBuilderConstants.PRIMO_BUILDER_REMOVE_ASSET_ERROR:
        case PrimoBuilderConstants.PRIMO_BUILDER_UPDATE_SUCCESS:
        case PrimoBuilderConstants.PRIMO_BUILDER_UPDATE_ERROR:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};
