import React, { Suspense, useState, useEffect } from "react";
import SideMenu from "./menu/menu";
import { Outlet, useLocation } from 'react-router-dom';
import ErrorBoundary from "./error-boundary";
import $ from "jquery"
import { isPast } from "date-fns"
import { store } from '../redux/store'
import ClearCache from "./ClearCache";
import { Loader } from "./react-overlay-loader";
import { authUserUsingToken } from "../redux/user/user.actions"
import { startSession, logSession, startActiveSession, setActiveTimeZero, DEFAULT_EVENTS, setViewSessionTimeZero, startViewSession } from "../redux/event/event.actions"
import { renderMaintenanceInfo } from '../maintenance'
import { config } from '../helpers'
import ReactGA from 'react-ga4';
function App(props) {
  let location = useLocation();

  const [prevLocation, setPrevLocation] = useState("")
  const [isChatlioReady, setIsChatlioReady] = useState(false)


  let user = store.getState().user
  let userName = ""
  if (user.firstName) {
    userName = user.firstName
  }

  function afterChatlioReady() {
    setIsChatlioReady(true)
  }

  function afterChatlioMessageReceived() {

    let container = $('#chatlio-message-container');
    if (container && container.length) {
      let height = container[0].scrollHeight;
      container.scrollTop(height)
    }

  }


  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {

      DEFAULT_EVENTS.map(function (num) {
        return window.addEventListener(num, e => { setActiveTimeZero() }, false)
      });

      window.addEventListener("focus", e => { setViewSessionTimeZero(); }, false)
      window.addEventListener("load", e => { startSession(); startActiveSession(); }, false)

    }

    /* No token */
    window.addEventListener('storage', e => {

      if (e.key === config.bbToken && e.oldValue && !e.newValue) {// logout automatically in all open tabs

        store.dispatch({ type: "UNAUTH_USER" });
      }
      else if (e.key === config.bbToken && e.newValue && !e.oldValue) {  // login automatically in all open tabs
        store.dispatch(authUserUsingToken("/login", ""))
      }

    });

    /* Chatlio */

    window.addEventListener("chatlio.messageReceived", afterChatlioMessageReceived(), false);
    window.addEventListener('chatlio.ready', afterChatlioReady(), false);


    return () => {
      window.removeEventListener('chatlio.messageReceived', afterChatlioMessageReceived(), false);
      window.removeEventListener('chatlio.ready', afterChatlioReady(), false);
    }

  }, [])

  useEffect(() => {
    if (isChatlioReady) {

      let user = store.getState().user

      window._chatlio.show({ expanded: false });
      if (user && !user.authenticated) {
        window._chatlio.hide();
      }
      let userName = ""
      if (user.firstName) {
        userName = user.firstName

        let header = `<div class="big-header">
      <img class="big-logo" src="/images/icons/big-bot.png" alt="Support">
          <span id="hi">Hi </span><span id="chat-welcome-text">${userName || ""}</span>
      </div>`

        $(".chatlio-widget-body").prepend(header);
      }
    }

  }, [userName, isChatlioReady])

  /* Location change effect */
  useEffect(() => {
    let user = store.getState().user;
    if (process.env.NODE_ENV !== "development") {

      ReactGA.initialize('G-WBY6F0CW96');
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

      setPrevLocation(location.pathname + location.search)
      let maintenance = user.maintenance || false;
      let localMaintenance = localStorage.getItem("bb-maintenance");
      let mId = false;
      if (localMaintenance) {
        mId = localMaintenance.replace("ack~", "");
      }

      // eslint-disable-next-line
      if (maintenance && maintenance.scheduled && maintenance.id && !isPast(new Date(maintenance.date + "T" + maintenance.start_time + ":00.000+10:00")) && (mId != maintenance.id)) {
        renderMaintenanceInfo(maintenance);
      }
    }
    if (window._chatlio) {
      if (user && user.authenticated) {
        let name = `${user.firstName} ${user.lastName}` || ""
        let email = user.email || ""
        let role = user.roleName || ""
        let userText = $("#chat-welcome-text").text()
        if (userText !== user.firstName) {
          $("#chat-welcome-text").text(user.firstName)
        }
        window._chatlio.identify(name, {
          name: name,
          email: email,
          role: role
        });
        window._chatlio.show({ expanded: window._chatlio.isExpanded && window._chatlio.isExpanded() ? true : false });
      } else {
        window._chatlio.hide();
      }
    }


  }, [location]);

  /* Location previous change effect */
  useEffect(() => {

    if (process.env.NODE_ENV !== "development") {

      window.addEventListener("blur", e => { startViewSession(prevLocation, store) }, false)
      window.addEventListener("beforeunload", (e) => { e.stopImmediatePropagation(); store.dispatch(logSession(prevLocation, true)); }, false)
    }
  }, [prevLocation]);

  return (
    <React.Fragment>
      <ClearCache auto={true} storageKey="BUDDY-APP-VERSION">

        {// eslint-disable-next-line
          ({ }) => (
            //(!loading && !isLatestVersion) && notify("Reloading Buddy to apply the latest updates.", "s")
            <></>
          )}
      </ClearCache>
      <div className="bb-container">
        <SideMenu location={location} />
        <div id="bb-container-main" className="bb-container-main">
          <ErrorBoundary>
            <Suspense fallback={<Loader containerStyle={{ backgroundColor: "transparent" }} loading={true} fullPage text="Loading..." />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>

    </React.Fragment>
  );

}


export default App;
